import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Feedback = styled.div`
  position: fixed;
  z-index: 1;
  right: -72px;
  top: 75%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 180px;
  height: 36px;
  background-color: rgb(5, 150, 105);
  box-shadow: rgba(0, 0, 0, 0.2) -1px -1px 8px;
  transform: rotate(270deg);
  @media screen and (min-width: 640px) {
    top: 50%;
  }
`;

const FeedbackText = styled.span`
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
`;

const GiveFeedback = () => (
  <Feedback>
    <Link
      href="https://form.typeform.com/to/AKCj2fnT?utm_source=www.kumalearn.com&utm_medium=web&utm_campaign=footer"
      target="_blank"
      rel="noopener"
    >
      <div className="flex items-center h-full">
        <FeedbackText>
          Feedback・アンケート
        </FeedbackText>
      </div>
    </Link>
  </Feedback>
);

export default GiveFeedback;
